@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;700;800;900&display=swap");
@import "../css/mixin.scss";
@import "../css/variables.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: #38847c #f5f5f5;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Overpass", sans-serif;
  overflow: auto !important;
}

.profile-basic-info .expdf-btn {
  background: #38847c;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.profile-basic-info .expdf-btn:hover {
  color: #38847c;
  border: 1px solid #38847c;
}
.profile-basic-info .expdf-btn:focus {
  box-shadow: none;
}

label {
  color: #898e8e;
  font-size: 14px !important;
  letter-spacing: 0.48px;
  font-family: "Overpass", sans-serif;
}

p {
  color: #191a1a;
  font-size: 16px;
  margin-bottom: 0;
}

select {
  @include selectBox;
}

input {
  @include inputBox;
}

textarea {
  resize: none;
  border-radius: 7px !important;
  border: 1px solid $inputBox-borderColor !important;
}

.form-check-input {
  background: #4fbfa434 0% 0% no-repeat padding-box;
  border-radius: 5px !important;
  opacity: 1;
  height: 16px;
  width: 16px;
}

.form-check-input:checked {
  background-color: #4fbfa4 !important;
}

.form-check-input:checked[type="checkbox"] {
  //    background-image: url(../images/tick_green.svg) !important;

  // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e);
}

hr {
  height: 1px;
  color: #ecf4f4;
  margin: 0.2rem 0.5rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #38847c;
}

@media all and (min-width: 768px) {
  .cx-active-sidebar {
    margin-left: 290px !important;
    transition: all 0.5s ease-in-out;
  }

  .cx-active-sidebarB {
    margin-left: 270px !important;
    transition: all 0.5s ease-in-out;
  }
}

input[type="file"] {
  display: none;
}

.btn-approve {
  color: #ffffff;
  border: none;
  background: #4fbfa4 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 5px 40px;
  font-size: 14px;
  font-weight: 600;
  flex-basis: 80%;
  width: 95%;
}

.btn-remove {
  background: #4fbfa419 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  padding: 3px 10px;
  flex-basis: 20%;
  width: 100%;
}

.vehicle-request-img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

.offered-by {
  text-align: left;
  font: normal normal 600 14px/22px Overpass;
  letter-spacing: 0.34px;
  color: #4fbfa4;
  opacity: 1;
}

.card-label {
  text-align: left;
  font: normal normal normal 14px/20px Overpass !important;
  letter-spacing: 0.42px;
  color: #898e8e;
  opacity: 1;
}

.small-card-data {
  text-align: left;
  font: normal normal 600 14px/22px Overpass;
  letter-spacing: 0.34px;
  color: #191a1a;
  opacity: 1;
}

.popup-approve-btn {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.38px;
  color: #4fbfa4;
  border: 1px solid #00bfa641;
  border-radius: 5px;
  opacity: 1;
  padding: 5px 15px;
  background: transparent;

  &:hover {
    transition: all ease-out 0.3s;
    background: #4fbfa419 0% 0% no-repeat padding-box;
    border: none;
    border: 1px solid #4fbfa419;
  }
}

.popup-reject-btn {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.38px;
  color: #DC3545;
  border: 1px solid #DC3545;
  border-radius: 5px;
  background: transparent;
  opacity: 1;
  padding: 5px 15px;

  &:hover {
    transition: all ease-out 0.3s;
    background: rgba(220,53,69,0.07) 0% 0% no-repeat padding-box;
    border: none;
    border: 1px solid #4fbfa419;
  }
}

.TransportersAssigned-btn {
  border: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.34px;
  color: #be8300;
  opacity: 1;
  background: transparent;
}

.WaitingforTransportersResponse-btn {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.34px;
  color: #898e8e;
  opacity: 1;
  border: none;
  background: transparent;
}

.green-link-btn {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.34px;
  color: #4fbfa4;
  opacity: 1;
  border: none;
  background: transparent;
}

.fix-offer-vehicles {
  .ViewSpecifications-link {
    letter-spacing: 0.34px;
    color: #4fbfa4;
    font-size: 14px;
    font-weight: 600;
  }

  label {
    font-size: 12px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
  }

  margin-left: -20px;
  position: fixed;
  bottom: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -4px 16px #0076ec1a;
  opacity: 1;
  height: 140px;
  width: 100%;
  overflow: auto;
}

.dropdown-menu.show {
  // inset: 4px -117px auto auto !important;
}

.modal-dialog-centered {
  justify-content: center;
}

a {
}

.active-arrowAndColor {
  opacity: 1;
  display: block;
}

img {
  vertical-align: middle;
}

table.dataTable thead th,
table.dataTable thead td {
  border: none !important;
}

table.dataTable {
  border: none !important;
  border-spacing: 0 10px !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 10px !important;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 10px !important;
}

table.dataTable thead,
th {
  font-size: 12px !important;
}

table.dataTable thead .sorting {
  background-image: none !important;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  display: none !important;
}

table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  display: none !important;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc {
  background: none !important;
  background-repeat: no-repeat !important;
}

.dataTables_paginate .paginate_button.current {
  color: #ffff !important;
  border: none !important;
  background: #4fbfa4 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #ffff !important;
  border: none !important;
  // background: #4FBFA4 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #ffff !important;
  border: none !important;
  background: #4fbfa4 !important;
}

table.dataTable thead .sorting_asc {
  background: none !important;
  background-repeat: no-repeat !important;
}

.dataTables_wrapper .dataTables_length {
  float: right !important;
}

.dataTables_wrapper .dataTables_filter {
  float: left !important;
}

.dataTables_wrapper .dataTables_filter input {
  background-color: #ffff !important;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  color: red;
}

.dataTables_wrapper .dataTables_info {
  color: #4fbfa4 !important;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 74px !important;
  height: 36px !important;
  background-color: #ffff !important;
}

div.dataTables_wrapper div.dataTables_filter input {
  height: 36px;
  width: 250px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  margin-bottom: 30px !important;
}

// ========= Dropdown Menu ============

.option-dropdown ul {
  button {
    color: #66739b;
    font-size: 12px;

    border-radius: 5px;

    &:hover {
      background-color: #4fbfa419;

      letter-spacing: 0.36px;
      color: #4fbfa4;
    }
  }

  padding: 10px;
}

#dropdown-transporterList {
  .arrowvehicle {
    position: absolute;
    left: 135px;
    bottom: 126px;
  }
}

#dropdown-ShiperList {
  .arrowvehicle {
    position: absolute;
    left: 135px;
    bottom: 103px;
  }
}

.dropdown-menu.show {
  position: absolute;
  inset: -29px 29px auto auto !important;
  margin: 0px;
  transform: translate3d(-8px, 32px, 0px) !important;
}

#transporterDetail-dropdown {
  .arrowvehicle {
    position: absolute;
    left: 135px;
    bottom: 128px;
  }

  .dropdown-menu.show {
    inset: -13px 46px auto auto !important;
  }
}

#vehicleDetail-dropdown {
  .arrowvehicle {
    position: absolute;
    left: 135px;
    bottom: 126px;
  }

  .dropdown-menu.show {
    inset: -34px 25px auto auto !important;
  }
}

table label,
th,
thead {
  font-size: 12px !important;
}

table p,
td,
tbody {
  font-size: 14px !important;
}

.change-driver-list {
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #191a1a;
  opacity: 1;
}

.btn-change-driver {
  background: #4fbfa4 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #ffff;
  font-size: 18px;
  font-weight: 600;

  &:hover {
    color: #ffff;
  }
}

.direct-order-main {
  .nav-pills .nav-link {
    margin-top: 7px;
  }
}

.card-heading-order-confirmation {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.56px;
  color: #38847c;
  background: #38847c0d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 7px 15px;
}

.order-confirm-table {
  .tr-body {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-radius: 7px;

    p {
      margin-bottom: 0 !important;
      padding: 5px;
    }
  }
}

.create-auction-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  .card {
    width: 400px;
    height: auto;
  }

  a {
  }

  .auction-btn {
    width: 100%;
    border: none;
    background: #4fbfa4 0% 0% no-repeat padding-box;
    font-weight: 600;
    color: #ffff;
    font-size: 16px;
    letter-spacing: 0.38px;
    border-radius: 8px;
    padding: 5px;
  }
}

.freights-search-select-main {
  .freights-search-select {
    flex-basis: 25%;
  }
}

.vehicle-detail-live {
  .arrowvehicle {
    bottom: 100px !important;
  }
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate("-50% -50%");
}

.border-block {
  .uploading {
    border: 1px dashed #d3dbdb;
    border-radius: 10px;
    text-align: center;
    padding: 70px;

    .rmsc .dropdown-container {
      position: relative;
      border-radius: 7px !important;
      outline: 0;
      width: 250px !important;
      background-color: #ffff !important;
      border: 1px solid #d1d5d9 !important;
    }
  }
}

.direct-order-main {
  margin-left: 70px;
  transition: all 0.5s;

  ul {
    background-color: #ffff;
  }
}

table.dataTable thead .sorting_asc {
  background-image: none !important;
}

.vehicle-requests-assign-transporter-popup {
  .modal-content {
    padding: 20px;
    width: 400px !important;
    min-width: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #2614070d;
    border-radius: 15px;
    opacity: 1;

    button {
    }

    .popup-reject-btn {
      width: 90% !important;

      a {
        text-decoration: none;
        color: #4fbfa4;
      }
    }

    .popup-approve-btn {
      width: 90% !important;
    }
  }
}

.modal-header .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/10px auto no-repeat;
}

.accordion-button::after {
  background-image: url(../images/sidebar/dropdown.svg) !important;
}

.offcanvas-header .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/10px auto no-repeat;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: none !important;
  margin-bottom: 20px;
  margin-top: -45px;
}

.vehicle-driver-detail-header {
  margin: 0 0 0 70px;
  transition: all 0.5s;

  ul {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000008;
  }
}

.all-request {
  .option-dropdown .arrowvehicle {
    bottom: 54px !important;
  }

  .dropdown-menu.show {
    inset: -32px 23px auto auto !important;
  }
}

.sub-heading-matchmaking {
  background: #eff5f4 0% 0% no-repeat padding-box;
  margin: 0 -12px;

  p {
    padding: 5px 30px;
    font-weight: 600;
    font-size: 16px !important;
  }
}

.form-outline {
  justify-content: flex-end;
  margin-bottom: 5px;

  select {
    width: 149px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.searchOrder {
  position: relative;

  .searchtab {
    top: 0.5rem;
    position: absolute;
    right: 20px;
  }

  input {
    height: 30px;
  }
}

.swal-button {
  background-color: #4fbfa4 !important;
}
.swal-text {
  font-size: 20px;
  color: red;
  font-weight: bold;
  letter-spacing: 0.5;
}

.swal-modal {
  padding: 20px;
  width: 350px;
}

.swal-text:first-child {
  margin-top: 0;
}

.swal-footer {
  padding: 0 !important;
}

.term-setting-main {
  p {
    text-align: justify;
  }
}

#main-updatePass-setting {
  // .left-section{
  //     display: none;
  // }
  // .GobackBtn-title .Go-back-btn{
  //     display: none;
  // }
  // .right-section{
  //     .form-control{
  //         background-color: transparent;
  //     }
  //     width: 100%;
  //     background-color: transparent;
  //      h3{
  //         display: none;
  //      }
  // }

  .GobackBtn-title {
    text-align: center;
    display: block;
  }
  .login-btn {
    a {
      font: normal normal bold 16px/23px Overpass;
      letter-spacing: 0.26px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 416px;
      text-decoration: none;
      background: #4fbfa4 0% 0% no-repeat padding-box;
      border-radius: 5px;
      height: 38px;
    }
  }
  .right-content {
    max-width: 416px;
    p {
      text-align: center;
    }
  }
  justify-content: center;
  display: flex;
  .form-control {
    background-color: transparent;
    width: 416px;
    height: 38px;
  }
}


